import Link from "next/link";

import { useState } from "react";

const Nav = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleMenuItem = (item) => {
    setActiveMenuItem(activeMenuItem === item ? null : item);
  };

  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            className={`${activeMenuItem === "home" ? "open" : ""}`}
            onClick={() => toggleMenuItem("home")}
            href="https://minhaeducacao.com.br"
          >
            Início
          </Link>
        </li>

        <li className="with-megamenu has-menu-child-item">
          <Link
            className={`${activeMenuItem === "courses" ? "open" : ""}`}
            href="https://plataforma.minhaeducacao.com.br/professores"
            onClick={() => toggleMenuItem("courses")}
          >
            Encontrar um professor
          </Link>
        </li>

        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            href="https://plataforma.minhaeducacao.com.br/aulas-grupo"
            className={`${activeMenuItem === "elements" ? "open" : ""}`}
            onClick={() => toggleMenuItem("elements")}
          >
            Aulas em Grupo
          </Link>
        </li>
        <li className="with-megamenu has-menu-child-item position-static">
          <Link
            href="https://comunidade.minhaeducacao.com.br"
            className={`${activeMenuItem === "blog" ? "open" : ""}`}
            onClick={() => toggleMenuItem("blog")}
          >
            Comunidade
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Nav;
