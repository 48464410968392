import Link from "next/link";

import { useSelector } from "react-redux";

import User from "../Offcanvas/User";
import { useAppContext } from "@/context/Context";

const HeaderRightTwo = ({ btnClass, btnText, userType }) => {
  const { mobile, setMobile, search, setSearch, cartToggle, setCart } =
    useAppContext();

  const { total_items } = useSelector((state) => state.CartReducer);

  return (
    <div className="header-right">
      <ul className="quick-access">
        {/*<li className="access-icon">
          <Link
            className={`search-trigger-active rbt-round-btn ${
              search ? "" : "open"
            }`}
            href="#"
            onClick={() => setSearch(!search)}
          >
            <i className="feather-search"></i>
          </Link> 
        </li>*/}

        <li className="account-access rbt-user-wrapper d-none d-xl-block">
          <Link href="https://plataforma.minhaeducacao.com.br/login">
            <i className="feather-user"></i>
            {userType}
          </Link>
        </li>

        <li className="access-icon rbt-user-wrapper d-block d-xl-none">
          <Link
            className="rbt-round-btn"
            href="https://plataforma.minhaeducacao.com.br/login"
          >
            <i className="feather-user"></i>
          </Link>
        </li>
      </ul>

      <div className="rbt-btn-wrapper d-none d-xl-block">
        <Link
          className={`rbt-btn ${btnClass}`}
          href="https://plataforma.minhaeducacao.com.br/regitro-professor"
        >
          <span data-text={`${btnText}`}>{btnText}</span>
        </Link>
      </div>

      <div className="mobile-menu-bar d-block d-xl-none">
        <div className="hamberger">
          <button
            className="hamberger-button rbt-round-btn"
            onClick={() => setMobile(!mobile)}
          >
            <i className="feather-menu"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeaderRightTwo;
