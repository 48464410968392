import Head from "next/head";

const PageHead = ({ title }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="description"
          content="Faça aulas individuais ou em grupo com a confiança de professores certificados. Encaixe na sua agenda e orçamento."
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />

        <meta
          name="description"
          content="Faça aulas individuais ou em grupo com a confiança de professores certificados. Encaixe na sua agenda e orçamento."
        />
        <meta
          name="keywords"
          content="Aulas particulares, Redação, Enem, Matemática, Física, Química, Biologia"
        />
        <meta name="application-name" content="minhaeducacao.com.br" />
        <meta property="twitter:card" content="summary" />
        <meta property="twitter:site" content="www.minhaeducacao.com.br" />
        <meta property="twitter:title" content={title} />
        <meta
          property="twitter:description"
          content="Faça aulas individuais ou em grupo com a confiança de professores certificados. Encaixe na sua agenda e orçamento."
        />
        <meta property="twitter:url" content="https://minhaeducacao.com.br/" />
        <meta property="busca:title" content="Minha Educação" />
        <meta itemprop="name" content="minhaeducacao.com.br" />
        <meta itemprop="url" content="https://www.minhaeducacao.com.br/" />
        <meta
          itemprop="description"
          content="Faça aulas individuais ou em grupo com a confiança de professores certificados. Encaixe na sua agenda e orçamento."
        />
        <link rel="canonical" href="https://www.minhaeducacao.com.br" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.minhaeducacao.com.br/" />
        <meta
          property="og:description"
          content="Faça aulas individuais ou em grupo com a confiança de professores certificados. Encaixe na sua agenda e orçamento."
        />
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:site_name" content="Minha Educação" />

        <meta charset="utf-8" />
      </Head>
    </>
  );
};

export default PageHead;
