import Link from "next/link";
import Separator from "../Common/Separator";

const CopyRight = () => {
  return (
    <>
      <Separator />
      <div className="copyright-area copyright-style-1 ptb--20">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <p className="rbt-link-hover text-center text-lg-start">
                Copyright © {new Date().getFullYear()}{" "}
                <Link href="https://outin.com.br">
                  Outin Soluções em Processamento de Dados LTDA
                </Link>{" "}
                <br />
                CNPJ 30.697.931/0001-51. Todos os direitos reservados
              </p>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12">
              <ul className="copyright-link rbt-link-hover justify-content-center justify-content-lg-end mt_sm--10 mt_md--10">
                <li>
                  <Link href="https://www.minhaeducacao.com.br/termos-servico">
                    Termos de Serviço
                  </Link>
                </li>
                <li>
                  <Link href="https://www.minhaeducacao.com.br/politica-privacidade">
                    Política de privacidade
                  </Link>
                </li>
                <li>
                  <Link href="https://plataforma.minhaeducacao.com.br/login">
                    Acessar
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
